import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  // {
  //   label: 'Home',
  //   icon: 'HomeIcon',
  //   href: '/',
  // },


  {
    label: 'Dollar To Dollar',
    icon: 'HomeIcon',
    href: '/',
  },

  {
    label: 'Pegged Price Swap',
    icon: 'HomeIcon',
    href: '/peggedPresale',
  },
  // {
  //   label: 'Voting',
  //   icon: 'LayerIcon',
  //   href: '/voting',
  // },
  // {
  //   label: 'Trade',
  //   icon: 'TradeIcon',
  //   initialOpenState: true,
  //   items: [
  //     {
  //       label: 'Exchange',
  //       href: 'https://exchange.privacyswap.finance/#/swap?inputCurrency=BNB&outputCurrency=0x7762a14082ab475c06d3868b385e46ae27017231'
  //     },
  //     {
  //       label: 'Liquidity',
  //       href: 'https://exchange.privacyswap.finance/#/add/BNB/0x7762A14082Ab475C06D3868B385E46aE27017231'
  //     }

  //     {
  //       label: 'Exchange',
  //       href: 'https://exchange.privacyswap.finance/#/swap',
  //     },
  //     {
  //       label: 'Liquidity',
  //       href: 'https://exchange.privacyswap.finance/#/pool',
  //     },
  //   ],
  // },
  // {
  //   label: 'Exchange',
  //   icon: 'TradeIcon',
  //   href: 'https://exchange.privacyswap.finance/#/swap',
  // },
  // {
  //   label: 'Liquidity',
  //   icon: 'TicketIcon',
  //   href: 'https://exchange.privacyswap.finance/#/pool', // https://old.privacyswap.finance/pools
  // },
  // {
  //   label: 'Farm',
  //   icon: 'FarmIcon',
  //   href: '/farms',
  // },

  // {
  //   label: 'Pools',
  //   icon: 'IfoIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Launchpool',
  //   icon: 'IfoUpdatedIcon',
  //   href: '/launchpool',
  // },

  // {
  //   label: 'NFT Launchpad',
  //   icon: 'InfoIcon',
  //   href: '/nftF',
  // },
  // {
  //   label: 'NFT Earn',
  //   icon: 'TradeUpdatedIcon',
  //   href: '/nftE',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/goose-finance',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/goose-finance/',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  //     },
  //   ]
  // },

  // {
  //   label: 'Price Charts',
  //   icon: 'InfoIcon',
  //   initialOpenState: true,
  //   items: [
  //     {
  //       label: 'Dex Guru',
  //       href: 'https://dex.guru/token/0x7762A14082Ab475C06D3868B385E46aE27017231-bsc',
  //     },
  //     {
  //       label: 'Poo Coin',
  //       href: 'https://poocoin.app/tokens/0x7762A14082Ab475C06D3868B385E46aE27017231',
  //     },
  //     {
  //       label: 'Bogged Finance',
  //       href: 'https://charts.bogged.finance/?token=0x7762A14082Ab475C06D3868B385E46aE27017231',
  //     },
  //     {
  //       label: 'Dex Tools',
  //       href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/0xcd18e1151a4831e5ebcecc611369123012674253',
  //     },
  //   ],
  // },

  // {
  //   label: 'Referal Program',
  //   icon: 'PoolUpdatedIcon',
  //   href: '/referral',
  // },

  // {
  //   label: 'Analytics',
  //   icon: 'MoreIcon',
  //   href: '/analytics',
  // },
  // {
  //   label: 'IDO',
  //   icon: 'NftIcon',
  //   href: '/ido',
  // },

  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/privacyswap/',
  //     },
  //     {
  //       label: 'Docs',
  //       href: 'https://docs.privacyswap.finance/',
  //     },
  //   ],
  // },
]

export default config

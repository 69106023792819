/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { getMyNFTs } from 'apollo'

import { getMasterChefAddress, getNFTAddress } from 'utils/addressHelpers'
import { useNFT } from 'hooks/useContract'
import { NFT, NFTState } from '../types'

const rawNFTS: NFT[] = [
  { id: '1', name: 'Silver', image: '/nfts/prv-silver.jpeg', balance: [], isApproved: false },
  { id: '2', name: 'Gold', image: '/nfts/prv-gold.jpeg', balance: [], isApproved: false },
  {
    id: '3',
    name: 'Diamond',
    image: '/nfts/prv-diamond.jpeg',
    balance: [1, 3, 4],
    isApproved: false,
  },
]

const initialState: NFTState = { data: [...rawNFTS] }

export const NFTSlice = createSlice({
  name: 'NFTs',
  initialState,
  reducers: {
    updateNFTPublicData: (state, action) => {
      const nfts: NFT[] = action.payload
      state.data = state.data.map((nft) => {
        const nftsData = nfts.find((entry) => entry.id === nft.id)
        return { ...nfts, ...nftsData }
      })
    },
  },
})

// Actions
export const { updateNFTPublicData } = NFTSlice.actions

export const fetchNFTUserDataAsync = (account, nftContract) => async (dispatch) => {
  const approval = await nftContract.methods.isApprovedForAll(account, getMasterChefAddress()).call()
  const myNFTs = await getMyNFTs(account)

  const type1Bal = []
  const type2Bal = []
  const type3Bal = []

  try {
    myNFTs.data.nfts.map((nft) => {
      const payload = {
        isStaked: nft.isStaked,
        id: parseInt(nft.id, 16),
      }
      if (nft.type === '1') {
        type1Bal.push(payload)
      } else if (nft.type === '2') {
        type2Bal.push(payload)
      } else if (nft.type === '3') {
        type3Bal.push(payload)
      }
      return nft
    })
  } catch (err) {
    console.log('sdssdsd', err)
  }

  const data = rawNFTS.map((nft) => {
    let bal = []
    if (nft.id === '1') {
      bal = type1Bal
    } else if (nft.id === '2') {
      bal = type2Bal
    } else if (nft.id === '3') {
      bal = type3Bal
    }

    return {
      ...nft,
      isApproved: approval,
      balance: bal,
    }
  })

  dispatch(updateNFTPublicData(data))
}

export default NFTSlice.reducer

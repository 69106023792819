import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  // {
  //   pid: 0,
  //   risk: 5,
  //   lpSymbol: 'PYDEX',
  //   lpAddresses: {
  //     97: contracts.cake[97],
  //     56: contracts.cake[56],
  //   },
  //   tokenSymbol: 'PYDEX',
  //   isTokenOnly: true,
  //   tokenAddresses: {
  //     97: contracts.cake[97],
  //     56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },

  // {
  //   pid: 1,
  //   risk: 5,
  //   lpSymbol: 'PRV-BUSD LP',
  //   lpAddresses: {
  //     97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
  //     56: '0xea275c14414de38f5c19c1e3559af032cc1facf4',
  //   },
  //   tokenSymbol: 'PRV',
  //   tokenAddresses: {
  //     97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
  //     56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },

  // {
  //   pid: 0,
  //   risk: 0,
  //   lpSymbol: 'PRV-BNB LP',
  //   lpAddresses: {
  //     97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
  //     56: '0xCd18e1151a4831e5EbCEcC611369123012674253',
  //   },
  //   tokenSymbol: 'PRV',
  //   tokenAddresses: {
  //     97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
  //     56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
]

export default farms

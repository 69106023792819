import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import Voting from 'views/Voting/Voting'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Lottery = lazy(() => import('./views/Lottery'))
const Presale = lazy(() => import('./views/Presale'))
const PeggedPrice =  lazy(() => import('./views/PeggedPrice'))
// const Pools = lazy(() => import('./views/Pools'))
// const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
const Info = lazy(() => import('./views/Info'))
const Referal = lazy(() => import('./views/referral/Referal'))
const LaunchPools = lazy(() => import('./views/Launchpool/Launchpool'))

const Nft = lazy(() => import('./views/Nft'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  useEffect(() => {
    const url = new URL(window.location.href)
    const refAddress = url.searchParams.get('ref')
    console.log(refAddress, 'ref address')
    if (refAddress) {
      localStorage.setItem('ref', refAddress)
    }
  }, [])

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Presale />
            </Route>

            <Route path="/peggedPresale" exact>
              <PeggedPrice />
            </Route>

            {/* <Route path="/" exact>
              <Home />
            </Route> */}
            {/* <Route path="/voting">
              <Voting />
            </Route> */}
            {/* <Route path="/farms">
              <Farms />
            </Route> */}
            {/* <Route path="/pools">
              <Farms tokenMode />
            </Route> */}
            {/* <Route path="/launchpool">
              <LaunchPools />
            </Route> */}

            {/* <Route path="/redeem">
              <RedeemNRT />
            </Route> */}

            {/* <Route path="/pools"> */}
            {/*  <Pools /> */}
            {/* </Route> */}
            {/* <Route path="/privacyPot">
              <Lottery />
            </Route> */}

            {/* <Route path="/info">
              <Info />
            </Route>
            <Route path="/referral">
              <Referal />
            </Route> */}
            {/* <Route path="/ifo"> */}
            {/*  <Ifos /> */}
            {/* </Route> */}
            {/* <Route path="/nft">
              <Nft />
            </Route> */}
            {/* Redirect */}
            {/* <Route path="/staking"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* <Route path="/syrup"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      <NftGlobalNotification />
      <ToastContainer />
    </Router>
  )
}

export default React.memo(App)
